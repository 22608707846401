.glightbox-container {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  backface-visibility: hidden;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999999 !important;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: relative;
  overflow: hidden;
}

.glightbox-container .gslider {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: transform .4s, -webkit-transform .4s;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  display: flex !important;
}

.glightbox-container .gslide {
  width: 100%;
  user-select: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe, .gslide video {
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
  border: none;
  outline: 0 !important;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  float: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  min-width: 200px;
  border: none;
  outline: 0;
  margin: auto;
  padding: 0;
  display: block;
}

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  transition: none;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.gslide-video {
  max-width: 100vh;
  position: relative;
  width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto;
}

.gslide-video:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #ff000057;
  display: none;
  position: absolute;
}

.gslide-video.playing:before {
  display: none;
}

.gslide-video.fullscreen {
  min-width: 100%;
  height: 75vh;
  max-width: 100% !important;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  text-align: left;
  max-height: calc(100vh - 40px);
  max-width: 100%;
  background: #fff;
  margin: auto;
  overflow: auto;
}

.gslide-inline .ginlined-content {
  width: 100%;
  padding: 20px;
}

.gslide-inline .dragging {
  transition: none;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.ginlined-content {
  opacity: 1;
  overflow: auto;
  display: block !important;
}

.gslide-external {
  width: 100%;
  min-width: 100%;
  max-height: 75vh;
  height: 100%;
  background: #fff;
  padding: 0;
  display: flex;
  overflow: auto;
}

.gslide-media {
  width: auto;
  display: flex;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-bottom .gslide-media, .desc-top .gslide-media {
  flex-direction: column;
  margin: 0 auto;
}

.gslide-description {
  flex: 1 0 100%;
  position: relative;
}

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom, .gslide-description.description-top {
  width: 100%;
  margin: 0 auto;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description, .glightbox-button-hidden {
  display: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  width: 100%;
  max-height: 78vh;
  background: linear-gradient(#0000 0, #000000bf 100%);
  padding: 19px 11px 50px;
  transition: opacity .3s linear;
  position: absolute;
  bottom: 0;
  height: auto !important;
  max-width: 100vw !important;
  order: 2 !important;
  overflow: auto !important;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: .4;
}

.gdesc-open .gslide-media {
  opacity: .4;
  transition: opacity .5s;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  opacity: 1;
  transition: opacity .5s;
}

.greset {
  transition: all .3s;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  z-index: 9999;
  border: 2px solid #fff;
  border-right-color: #0000;
  border-radius: 50%;
  margin: 0 auto;
  animation: lightboxLoader .8s linear infinite;
  display: block;
  position: absolute;
  top: 47%;
  left: 0;
  right: 0;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  will-change: opacity;
  background: #000;
  position: fixed;
  top: -1px;
  left: 0;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gclose, .gnext, .gprev {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gclose svg, .gnext svg, .gprev svg {
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
}

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: .1;
}

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  color: #000;
  margin-bottom: 19px;
  font-family: arial;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  margin-bottom: 0;
  font-family: arial;
  font-size: .86em;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: #000000bf;
  border-radius: 4px;
}

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path {
  fill: #fff;
}

.glightbox-clean .gprev {
  width: 40px;
  height: 50px;
  position: absolute;
  top: -100%;
  left: 30px;
}

.glightbox-clean .gnext {
  width: 40px;
  height: 50px;
  position: absolute;
  top: -100%;
  right: 30px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

.gfadeIn {
  animation: gfadeIn .5s;
}

.gfadeOut {
  animation: gfadeOut .5s;
}

.gslideOutLeft {
  animation: gslideOutLeft .3s;
}

.gslideInLeft {
  animation: gslideInLeft .3s;
}

.gslideOutRight {
  animation: gslideOutRight .3s;
}

.gslideInRight {
  animation: gslideInRight .3s;
}

.gzoomIn {
  animation: gzoomIn .5s;
}

.gzoomOut {
  animation: gzoomOut .5s;
}

@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes gfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes gslideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-60%, 0, 0);
  }

  to {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-60%, 0, 0);
  }
}

@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    transform: translate3d(60%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(60%, 0, 0);
  }
}

@keyframes gzoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 1;
  }
}

@keyframes gzoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    flex-direction: row;
  }

  .glightbox-container .ginner-container.desc-top .gslide-description {
    order: 0;
  }

  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    order: 1;
  }

  .glightbox-container .ginner-container.desc-left .gslide-description {
    order: 0;
  }

  .glightbox-container .ginner-container.desc-left .gslide-image {
    order: 1;
  }

  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }

  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }

  .gslide-inline {
    max-height: 95vh;
  }

  .gslide-external {
    max-height: 100vh;
  }

  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px;
  }

  .glightbox-open {
    height: auto;
  }

  .goverlay {
    background: #000000eb;
  }

  .glightbox-clean .gslide-media {
    box-shadow: 1px 2px 9px #000000a6;
  }

  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    height: 100%;
    position: absolute;
    overflow-y: auto;
  }

  .glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background-color: #00000052;
  }

  .glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background-color: #000000b3;
  }

  .glightbox-clean .gprev, .glightbox-clean .gnext {
    top: 45%;
  }
}

@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: .7;
    right: 20px;
  }
}

@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}

/*# sourceMappingURL=index.75421911.css.map */
